/** @jsx jsx */
import { Box, Grid, Container, jsx } from "theme-ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import AguilaButton from "components/AguilaButton"
import SectionHeading from "components/SectionHeading"
import MdContent from "components/common/MdContent"

interface CategoryParentSectionProps {
  //enables sx prop to be passed down from parent
  className?: string
  title: string
  // content: string
  bulletImage: IGatsbyImageData
  bulletImageAlt: string
  categoryImage: IGatsbyImageData
  categoryImageAlt: string
  buttonText: string
  buttonUrl: string
  categoryPageDescription: string
  categoryListDescription: string
}

const CategoryParentSection = ({
  bulletImage,
  bulletImageAlt,
  buttonText,
  buttonUrl,
  categoryImage,
  categoryImageAlt,
  categoryListDescription,
  categoryPageDescription,
  className,
  title,
}: CategoryParentSectionProps) => {
  return (
    <Box as="section" sx={{ my: 10 }} className={className}>
      <SectionHeading>{title}</SectionHeading>
      <Container sx={{ py: [0, null, null, 14] }}>
        <Grid
          sx={{
            gridTemplateColumns: "clamp(77px, 20vw, 220px) auto",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              gridRow: "1 / 3",
              height: ["200px", null, null, "450px"],
              mr: 6,
            }}
          >
            <GatsbyImage
              image={bulletImage}
              alt={bulletImageAlt}
              objectFit="contain"
              objectPosition="top"
              sx={{ height: "100%", width: "auto" }}
            />
          </Box>
          <Box sx={{ mb: [8, 14] }}>
            {/* <Paragraph sx={{ maxWidth: "775px", mb: 5 }}>
              {categoryPageDescription}
            </Paragraph>
            <Box
              sx={{
                mb: [8, null, null, 16],
                ...(theme.styles as ThemeUIStyleObject),
              }}
              dangerouslySetInnerHTML={{ __html: categoryListDescription }}
            ></Box> */}
            <MdContent
              source={categoryListDescription}
              sx={{
                maxWidth: "775px",
                mb: [8, null, null, 16],
              }}
            />
            <AguilaButton variant={"secondary"} url={buttonUrl} text={buttonText} />
          </Box>
          <Box
            sx={{
              position: "relative",
              gridColumn: ["1 / -1", "2 / -1"],
              pb: [2.5, null, 7],
              pr: [2.5, null, 7],
              "&:after": {
                content: '""',
                bg: "primary",
                position: "absolute",
                bottom: 0,
                right: 0,
                width: ["calc(100% - .625rem)", null, "calc(100% - 1.75rem)"],
                height: ["calc(100% - .625rem)", null, "calc(100% - 1.75rem)"],
                zIndex: -1,
              },
            }}
          >
            <GatsbyImage
              image={categoryImage}
              alt={categoryImageAlt}
              sx={{ gridColumn: ["1 / -1", "2 / -1"] }}
            />
            {/* <StaticImage
                src="../../images/ammunition/handgun-main.png"
                alt="handgun bullets"
                sx={{ gridColumn: ["1 / -1", "2 / -1"] }}
              /> */}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default CategoryParentSection
